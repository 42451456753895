import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Recebimento } from '../../../model/pcp/recebimento/recebimento';

@Injectable({
  providedIn: 'root'
})
export class RecebimentoService {
  private url = `${environment.url}/pcp/inspecao-recebimento`;

  constructor(
    private http: HttpClient
  ) {

  }

  public criarInspecaoRecebimento(recebimento: Recebimento): Observable<Recebimento> {
    return this.http.post<Recebimento>(`${this.url}`, recebimento);
  }
  public criarInspecaoRecebimentoPorChaveDeAcesso(chaveDeAcesso: string): Observable<Recebimento> {
    return this.http.post<Recebimento>(`${this.url}/${chaveDeAcesso}`, null);
  }
  public editarInspecaoRecebimento(recebimento: Recebimento): Observable<Recebimento> {
    return this.http.put<Recebimento>(`${this.url}`, recebimento);
  }
  public salvarImagensRecebimento(id:number,formData:FormData):Observable<any>{
    return this.http.post<any>(`${this.url}/imagem/${id}`,formData);
  }

  public consultaInspecaoRecebimento(paginaAtual:number,totalPorPagina:number): Observable<Recebimento[]> {
    return this.http.get<Recebimento[]>(`${this.url}/${paginaAtual}/${totalPorPagina}`);
  }
  public consultaPorId(id:number): Observable<Recebimento> {
    return this.http.get<Recebimento>(`${this.url}/${id}`);
  }
  public consultaPorFiltro(paginaAtual:number,totalPorPagina:number,valor:string): Observable<Recebimento[]> {
    return this.http.get<Recebimento[]>(`${this.url}/filtro/${paginaAtual}/${totalPorPagina}/${valor}`);
  }

  public consultaPorStatus(paginaAtual:number,totalPorPagina:number,status:string): Observable<Recebimento[]> {
    return this.http.get<Recebimento[]>(`${this.url}/status/${paginaAtual}/${totalPorPagina}/${status}`);
  }

  public deletarImagem(id:number):Observable<any>{
    return this.http.delete<Recebimento>(`${this.url}/imagem/${id}`);
  }



}
